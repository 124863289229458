<template>
	<ion-page>
		<Header currentPage="about-us"/>
		<ion-content>
			<div>
				<div class="top-section">
					<div class="container">
						<div class="text-wrapper">
							<h1>O nás</h1>
							<p>Za platformou OpenAcademy a jej vzdelávacím obsahom pre vývoj hybridných aplikácií, online hier a IoT stojí <a href="https://openlab.sk/" target="_blank"> vzdelávacia organizácia OpenLab</a> spolu so svojimi partnermi. OpenLab vytvára príležitosti pre stredoškolákov získavať soft skilly a tech skilly v technologických oblastiach 21. storočia vďaka úzkej spolupráci s odborníkmi z praxe LabMastrami.</p>
						</div>
						<img src="@/assets/images/Team-01.png" alt="illustration-image">
					</div>
				</div>

				<div class="row-wrapper">
					<div class="container">
						<ion-row>
							<ion-col lg="5" md="0" class="img-col">
								<div class="img-wrapper left">
									<img src="@/assets/images/26-10.png">
								</div>
							</ion-col>
							<ion-col lg="7" md="12">
								<h1>Prečo to robíme?</h1>
								<p>
									Našim zámerom je zvýšiť počet príležitostí pre študentov stredných škôl a gymnázií v oblasti rozvoja najnovších technologických smerov. A zároveň vytvoriť učiteľom možnosti vzdelávať technológie 21. storočia. Naše učebné materiály pre vývoj aplikácií, hier a IoT preto sprístupňujeme verejne prostredníctvom vzdelávacej platformy OpenAcademy.
								</p>
								<p>
									OpenAcademy umožňuje študentom naučiť sa nové technológie formou krátkych videí a drobných projektov. Študenti majú možnosť ukladať si videá a tiež zaznačovať si svoj progress tak, aby to ich učiteľ mohol skontrolovať.
								</p>
							</ion-col>
						</ion-row>
					</div>
				</div>

				<div class="row-wrapper">
					<div class="container">
						<ion-row>
							<ion-col lg="7" md="12">
								<h1>OpenAcademy pomáhali vyvíjať samotní študenti!</h1>
								<p>
									V OpenLabe sme študentom zdieľali učebné materiály cez Google Drive. 
									Nebolo to zlé, no študenti v tom videli príležitosť na zlepšenie - sprehľadnenie, zverejnenie a šírenie.
									Platforma <a href="https://www.open-academy.sk/" target="_blank"
									>OpenAcademy</a> vznikla počas školského roka 2021/22 v HybridLabe na 
									<a href="https://spsehalova.sk/" target="_blank"
									>SPŠE Hálova 16 v Bratislave</a> 
									ako študentský projekt. Nápadu sme sa spoločne chopili, podporila nás 
									<a href="https://spolocnost.o2.sk/ferova-nadacia" target="_blank"
									>Férová Nadácia O2</a>, <a href="https://sk-nic.sk/" target="_blank"
									>Fond SK-NIC. </a> a <a href="https://www.nadaciapontis.sk/projekty/generacia-3-0/" target="_blank">Nadácia Pontis.</a> 
									Vďaka podpore a odbornému vedeniu profesionálov na vývoj softvéru z 
									<a
										href="https://www.wezeo.com/" target="_blank"
									>WEZEO</a> sme vytvorili OpenAcademy - platformu na tvorbu a zdieľanie učebných materiálov. 
									Je fantastické vidieť, že prepájanie študentov a odborníkov dokáže viesť k zlepšeniu vecí okolo nás! 
									Veľká vďaka všetkým za spoluprácu!
								</p>
                                
							</ion-col>
							<ion-col lg="5" md="0" class="img-col">
								<div class="img-wrapper right">
									<img src="@/assets/images/chalani_baba_openlab.png">
								</div>
							</ion-col>
						</ion-row>
					</div>
				</div>

				<div class="row-wrapper">
					<div class="container">
						<ion-row>
							<ion-col lg="5" md="0" class="img-col">
								<div class="img-wrapper left">
									<img src="@/assets/images/businessman_brainstorming.png">
								</div>
							</ion-col>
							<ion-col lg="7" md="12">
								<h1 class="my-5 font-weight-bold">Prečo je to dôležité?</h1>
								<p>Vývoj aplikácií, vývoj hier a internet vecí (IoT) patria medzi dominantné a rastúce technologické segmenty. Na Slovensku vzniklo posledné roky množstvo súkromných spoločností, ktoré buď svoje produkty zakladajú na aplikačných a IoT riešeniach alebo poskytujú softvérové služby, kedy tieto riešenia vyvíjajú na mieru klientom. Tiež u nás vzniklo množstvo herných štúdií, ktoré majú veľký potenciál medzinárodne uspieť. Aplikácie, hry a IoT riešenia u nás vyvíjajú aj medzinárodné technologické spoločnosti a ľudia s týmito zručnosťami budú potrební aj v rámci verejného sektoru. Celosvetovo sa očakáva pokračujúci nárast dopytu po softvér riešeniach, online hrách a IoT produktoch, s čím bude narastať aj dopyt po odborníkoch na ich vývoj. Príležitostí vzdelávať sa v najnovších technológiách pre vývoj aplikácií a IoT riešení je však na Slovensku stále málo. Keďže sú tieto oblasti nové a neustále napredujú, chýbajú učitelia so zručnosťami aj učebné materiály. Medzi študentmi v OpenLabe vidíme veľký záujem o tieto technologické smery, máme skúsenosti aj odborníkov z priemyslu, preto chceme odovzdávať naše skúsenosti ďalej a pomáhať učiteľom a študentom, ktorí chcú rozvíjať seba a Slovensko.</p>
								<p class="font-weight-bold">
									Ak chcete vedieť viac o OpenLabe, navštívne našu stránku:
									<a href="http://www.openlab.sk" target="_blank">www.openlab.sk</a>.
									<br />Ak máte otázky, návrhy, či odporúčania, napíšte nám na
									<a href="mailto:info@openlab.sk">info@openlab.sk</a>.
								</p>
							</ion-col>
						</ion-row>
					</div>
				</div>
				<div class="row-wrapper">
					<div class="container text-center">
						<h2 class="mb-5">Ďakujeme za podporu a spoluprácu pri tvorbe digitálnych nástrojov!</h2>
						<ion-row>
							<ion-col lg="6" sm="12">
								<h5>Hlavný partner OpenAcademy 1.0 je <b>Férová Nadácia O2</b>.</h5>
								<img :src="require('@/assets/images/ferova-nadacia.jpg')" class="partners-logo" @click="window.open('https://spolocnost.o2.sk/ferova-nadacia')">
							</ion-col>
							<ion-col lg="6" sm="12">
								<h5>Projekt OpenAcademy 2.0 a jeho výsledky podporil <b>SK-NIC</b>.</h5>
								<img :src="require('@/assets/images/SK-NIC2020.png')" class="partners-logo" @click="window.open('https://sk-nic.sk/')">
							</ion-col>
						</ion-row>
					</div>
				</div>
			</div>
			<Footer />
		</ion-content>
	</ion-page>
</template>
<script>

import Footer from './components/Footer.vue'
import Header from './components/Header.vue'

export default {
	components: {Footer, Header},  
   
}
</script>
<style lang="scss" scoped>
	p,
	a  {
		line-height: 28px;
		font-size: 14px;
		font-weight: 100;
		
	}

	.top-section {
		background-color: #289FFD;
		color: #ffffff;
		position: relative;
        padding: 0px 30px 0px 30px;

		.container {
			padding: 90px 0 170px 0;

			.text-wrapper {
				max-width: 475px;

				p,
				a  {
					color: #ffffff;
				}

				a {
					text-decoration: underline;
				}
			}

			img {
				height: 400px;

				position: absolute;
				bottom: 0px;
				right: 58px;
			}
		}
	}

	@media only screen and (max-width: 1300px) {
		.top-section {
			.container {
				img {
					height: 300px !important;
				}
			}
		}
	}


	@media only screen and (max-width: 991px) {
		.row-wrapper, .top-section {
            text-align: center;
            padding: 0 30px 0 30px;
			img, .img-col {
				display: none;
			}

			.text-wrapper {
				max-width: 100% !important;
			}
		}
		
	}

	.row-wrapper {
		h1 {
			margin-bottom: 30px;
		}
		.container {
			padding: 96px 0 50px 0;

			.img-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;

				img {
					max-height: 70%;
				}
			}

			p, a {
				color: #000000;
			}

			a {
				text-decoration: underline;
			}

			.img-wrapper.left {
				// padding-right: 150px;
			}

			.img-wrapper.right {
				padding-left: 150px;
			}
		}
	}

	img.partners-logo {
		height: 100px;
		margin-top: 30px;
	}

	img.partners-logo:hover {
		cursor: pointer;
	}
//ble
h1 {
    line-height: 55px;
    font-size: 45px;
    font-weight: 900 !important;
    margin-bottom: 20px;
}
.left {
    float: left;
}
@media only screen and (max-width: 370px) {
	h1 {
		font-size: 24px;
	}
}
</style>